import React from "react";
import styles from "../../scss/pages/ReportDetail.module.scss";

import { Authenticator } from "../../components/authenticator/Authenticator";
import { IReport } from "../../data/models/Report";
import { ReportData } from "../../data/ReportData";
import { ReportDetailField } from "./ReportDetailField";
import { DateHelper } from "../../data/helpers/dateHelper";
import { ImageContainer } from "../../components/form/ImageContainer/ImageContainer";
import { fullWidthButtonStyles } from "../../scss/ControlStyles";
import { DefaultButton } from "@fluentui/react";
import { FileData } from "../../data/FileData";
import { DataLayer } from "../../data/DataLayer";
import { LocationTypeIdEnum } from "../../data/enums/LocationTypeEnum";
import { LocationTypeDescriptionEnum } from "../../data/enums/LocationTypeEnum";

interface IState {
  apiUri: string;
  files: string[];
  reportId: number;
  report?: IReport;
  surveyUrl: string;
  showSurvey: boolean;
}

export class ReportDetail extends React.Component<{}, IState> {

  constructor(props: {}) {
    super(props);

    const { reportId } = (this.props as any).match.params;
    var apiUri = process.env.REACT_APP_API_URI || "http://localhost:8080";

    var showSurvey: boolean = window.location.search === '?survey';

    this.state = {
      files: [],
      apiUri: apiUri,
      reportId: reportId,
      surveyUrl: '',
      showSurvey: showSurvey,
    };
  }

  render() {
    var createdDate = this.state.report ? this.state.report.createdAt : "";
    var formattedCreatedDate: string = DateHelper.formatDate(createdDate);

    var incidentDate = this.state.report ? this.state.report.incidentDate : "";
    var formattedIncidentDate: string = DateHelper.formatDate(incidentDate);

    return (
      <Authenticator
        redirectIfLoggedOut={true}
        onAuthenticated={this.loadData.bind(this)}
      >
        <div className={`${styles.row} ${styles.reportDetail}`}>
          <div
            className={`${styles.col} ${styles.sm10} ${styles.smOffset1} ${styles.printFullWidth}`}
          >
            <div className={`${styles.row}`}>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg8}`}>
                <h1>
                  {
                    this.state.report?.problemActivity?.productLine
                      ?.reportHeader
                  }{" "}
                  Performance Report
                </h1>
              </div>
              <div
                className={`${styles.col} ${styles.sm6} ${styles.lg2} ${styles.noPrint}`}
              >
                <DefaultButton
                  text="Submit a new form"
                  styles={fullWidthButtonStyles}
                  onClick={() =>
                    (window.location.href = `/report/${this.state.report?.problemActivity?.productLine?.id}`)
                  }
                />
              </div>
              <div
                className={`${styles.col} ${styles.sm6} ${styles.lg2} ${styles.noPrint}`}
              >
                <DefaultButton
                  text="Print"
                  styles={fullWidthButtonStyles}
                  onClick={() => window.print()}
                />
              </div>
            </div>

            <div className={`${styles.row}`} hidden={!this.state.showSurvey}>
              <div className={`${styles.col} ${styles.sm12}`}>
                <p>
                  <a href={this.state.surveyUrl} target='_blank' className={styles.survey}>Click here to take a survey on your Product Performance experience</a>
                </p>
              </div>
            </div>

            <div className={`${styles.row} ${styles.bgw} ${styles.details}`}>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg4}`}>
                <h3>Account Details</h3>

                <div className={`${styles.detailsTable}`}>
                  <ReportDetailField
                    text="Account"
                    value={this.state.report?.account?.account}
                    show
                  />
                  <ReportDetailField
                    text="Account Name"
                    value={this.state.report?.account?.name}
                    show
                  />
                  <ReportDetailField
                    text="Account Address"
                    value={`${this.state.report?.account?.address} ${this.state.report?.account?.city}`}
                    show
                  />
                  <ReportDetailField
                    text="QA Contact"
                    value={this.state.report?.qaContact}
                    show
                  />
                  <ReportDetailField
                    text="Submitted By"
                    value={this.state.report?.creator}
                    show
                  />
                  <ReportDetailField
                    text="Work Phone"
                    value={this.state.report?.creatorPhone}
                    show
                  />
                  <ReportDetailField
                    text="Fax"
                    value={this.state.report?.creatorFax}
                    show
                  />
                  <ReportDetailField
                    text="Email"
                    value={this.state.report?.creatorEmail}
                    show
                  />
                </div>

                <h3>Submission Details</h3>

                <div className={`${styles.detailsTable}`}>
                  <ReportDetailField
                    text="Submission Number"
                    value={this.state.report?.id}
                    show
                  />
                  <ReportDetailField
                    text="Submission Date"
                    value={formattedCreatedDate}
                    show
                  />
                  <ReportDetailField
                    text="Incident Date"
                    value={formattedIncidentDate}
                    show
                  />
                  <ReportDetailField
                    text="Customer Ref. Number"
                    value={this.state.report?.custRef}
                    show
                  />
                  <ReportDetailField
                    text="Notification Number"
                    value={this.state.report?.notification}
                    show
                  />
                  <ReportDetailField
                    text="Status"
                    value={this.state.report?.status}
                    show
                  />
                </div>
              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg4}`}>
                <h3>General Incident Information</h3>

                <div className={`${styles.detailsTable}`}>
                  <ReportDetailField
                    text="Did the issue occur during a donation?"
                    value={this.state.report?.problemActivity?.productLine?.showDuringDonation ? this.boolToString(this.state.report?.duringDonation) : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showDuringDonation
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.duringDonationAltText
                    }
                  />

                  <ReportDetailField
                    text="Was there an adverse event or injury?"
                    value={this.state.report?.problemActivity?.productLine?.showAdverseEvent ? this.boolToString(this.state.report?.adverseEvent) : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showAdverseEvent
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.adverseEventAltText
                    }
                  />

                  <ReportDetailField
                    text="Adverse event description"
                    value={this.state.report?.adverseEvent ? this.state.report?.adverseEventDescription : 'NA'}
                    show={this.state.report?.adverseEvent}
                  />

                  <ReportDetailField
                    text="List the name of any drug administered"
                    value={this.state.report?.problemActivity?.productLine?.showDrugAdministered ? this.state.report?.drugAdministered : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showDrugAdministered
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.drugAdministeredAltText
                    }
                  />

                  <ReportDetailField
                    text="Was the procedure successfully completed?"
                    value={this.state.report?.problemActivity?.productLine?.showCompleted ? this.state.report?.completed : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showCompleted
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.completedAltText
                    }
                  />

                  <ReportDetailField
                    text="Was the infusion stopped before completion?"
                    value={this.state.report?.problemActivity?.productLine?.showStopped ? this.state.report?.stopped : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showStopped
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.stoppedAltText
                    }
                  />

                  <ReportDetailField
                    text="What drug was used for the infusion?"
                    value={this.state.report?.problemActivity?.productLine?.showDrugUsed ? this.state.report?.drugUsed : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showDrugUsed
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.drugUsedAltText
                    }
                  />

                  <ReportDetailField
                    text="Is the drug used cytotoxic?"
                    value={this.state.report?.problemActivity?.productLine?.showIsCytotoxic ? this.state.report?.isCytotoxic : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showIsCytotoxic
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.isCytotoxicAltText
                    }
                  />

                  <ReportDetailField
                    text="Was the procedure stopped due to a soft goods incident?"
                    value={this.state.report?.problemActivity?.productLine?.showSoftGoods ? this.state.report?.softGoodsIssue : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showSoftGoods
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.softGoodsAltText
                    }
                  />

                  <ReportDetailField
                    text="Was the product lost?"
                    value={this.state.report?.problemActivity?.productLine?.showLost ? this.state.report?.lost : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine?.showLost
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.lostAltText
                    }
                  />

                  <ReportDetailField
                    text="Did the procedure involve clinical or patient material?"
                    value={this.state.report?.problemActivity?.productLine?.showClinicalOrPatientMaterial ? this.state.report?.clinicalOrPatientMaterial : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine?.showClinicalOrPatientMaterial
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.clinicalOrPatientMaterialAltText
                    }
                  />

                  <ReportDetailField
                    text="Manufacturer"
                    value={this.state.report?.manufacturer}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showManufacturer
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.manufacturerAltText
                    }
                  />

                  <ReportDetailField
                    text="Container"
                    value={this.state.report?.container}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showContainer
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.containerAltText
                    }
                  />
                </div>

                <h3>Product Information</h3>

                <div className={`${styles.detailsTable}`}>
                  <ReportDetailField
                    text="Product Line"
                    value={
                      this.state.report?.problemActivity?.productLine?.name
                    }
                    show
                  />

                  <ReportDetailField
                    text="Product Code"
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.productCodeAltText
                    }
                    value={this.state.report?.product?.code}
                    show
                  />

                  <ReportDetailField
                    text="Lot Number"
                    value={this.state.report?.lotNumber}
                    show
                  />

                  <ReportDetailField
                    text="Red Cell Container Label Identifier (Lower right corner – A or B)"
                    value={this.state.report?.rbcLabelId}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showRbcLabelId
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.rbcLabelIdAltText
                    }
                  />

                  <ReportDetailField
                    text="Number of Incidents"
                    value={this.state.report?.numIncidents}
                    show
                  />

                  <ReportDetailField
                    text="Instrument Serial Number"
                    value={this.state.report?.serialNumber}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showSerialNumber
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.serialNumberAltText
                    }
                  />

                  <ReportDetailField
                    text="Software Version"
                    value={this.state.report?.softwareVersion}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showSoftwareVersion
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.softwareVersionAltText
                    }
                  />

                  <ReportDetailField
                    text="Product Collected (ml)"
                    value={this.state.report?.productDescription}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showProductDescription
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.productDescriptionAltText
                    }
                  />

                  <ReportDetailField
                    text="WB Processed (ml)"
                    value={this.state.report?.wbProcessed}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showWbProcessed
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.wbProcessedAltText
                    }
                  />

                  <ReportDetailField
                    text="Volume of Plasma Collected (ml)"
                    value={this.state.report?.volumeOfPlasmaCollected}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showVolPlasma
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.volPlasmaAltText
                    }
                  />

                  <ReportDetailField
                    text="Donor Bleed Number"
                    value={this.state.report?.donorBleedNumber}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showDonorBleedNumber
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.donorBleedNumberAltText
                    }
                  />

                  <ReportDetailField
                    text="PAS Lot Number"
                    value={this.state.report?.pasLotNumber}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showPasLotNumber
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.pasLotNumberAltText
                    }
                  />

                  <ReportDetailField
                    text="Batch Tag Time (hh:mm:ss)"
                    value={this.state.report?.batchTagInfoTime}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showBatchTagTime
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.batchTagTimeAltText
                    }
                  />

                  <ReportDetailField
                    text="Batch Tag Number"
                    value={this.state.report?.batchTagNumber}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showBatchTagNumber
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.batchTagNumberAltText
                    }
                  />

                  <ReportDetailField
                    text="Line Number"
                    value={this.state.report?.lineNumber}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showLineNumber
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.lineNumberAltText
                    }
                  />

                  <ReportDetailField
                    text="Video Jet Number"
                    value={this.state.report?.videoJetNumber}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showVideoJetNumber
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.videoJetNumberAltText
                    }
                  />

                  <ReportDetailField
                    text="Filter Number"
                    value={this.state.report?.filterNumber}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showFilterNumber
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.filterNumberAltText
                    }
                  />
                </div>

                <h3
                  hidden={
                    !this.state.report?.problemActivity?.productLine
                      ?.showFilterIssueDetails
                  }
                >
                  Filter Issue Details
                </h3>

                <section
                  hidden={
                    !this.state.report?.problemActivity?.productLine
                      ?.showFilterIssueDetails
                  }
                >
                  <div className={`${styles.detailsTable}`}>
                    <ReportDetailField
                      text="NOT Filter related"
                      value={this.boolToString(
                        this.state.report?.filterNotRelated
                      )}
                      show
                    />

                    <ReportDetailField
                      text="How were the filters processed?"
                      value={this.state.report?.filterTemperature}
                      show={!this.state.report?.filterNotRelated}
                    />

                    <ReportDetailField
                      text="Which centrifuge cup/liner was used?"
                      value={this.state.report?.filterCentrifuge}
                      show={!this.state.report?.filterNotRelated}
                    />

                    <ReportDetailField
                      text="How long was the filtration time?"
                      value={this.state.report?.filtrationTime}
                      show={!this.state.report?.filterNotRelated}
                    />

                    <ReportDetailField
                      text="How much blood, relatively speaking, was left in the primary bag at the time of incident?"
                      value={this.state.report?.bloodAmount}
                      show={!this.state.report?.filterNotRelated}
                    />

                    <ReportDetailField
                      text="Was the filter inlet side flat or filled at the time of the incident?"
                      value={this.state.report?.filterFlatFilled}
                      show={!this.state.report?.filterNotRelated}
                    />

                    <ReportDetailField
                      text="If WBC failure, what was the actual value?"
                      value={this.state.report?.wbcValue}
                      show={!this.state.report?.filterNotRelated}
                    />
                  </div>
                </section>

                <h3
                  hidden={
                    !this.state.report?.problemActivity?.productLine
                      ?.showFilterIssueDetails
                  }
                >
                  Needle Issue Details
                </h3>

                <section
                  hidden={
                    !this.state.report?.problemActivity?.productLine
                      ?.showNeedleIssueDetails
                  }
                >
                  <div className={`${styles.detailsTable}`}>
                    <ReportDetailField
                      text="NOT Needle related"
                      value={this.boolToString(
                        this.state.report?.needleNotRelated
                      )}
                      show
                    />
                    <ReportDetailField
                      text="When was the condition detected?"
                      value={this.state.report?.needleDropdownOption?.description}
                      show={!this.state.report?.needleNotRelated}
                    />
                  </div>
                </section>

              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg4}`}>
                <h3>Product Return Details</h3>

                <div className={`${styles.detailsTable}`}>
                  <ReportDetailField
                    text="Response letter NOT requested"
                    value={this.boolToString(this.state.report?.noResponse)}
                    show={
                      !this.state.report?.account?.hideCorrespondence &&
                      this.state.report?.problemActivity?.productLine
                        ?.showCorrespondence
                    }
                  />
                  <ReportDetailField
                    text="Email address for letter recipient"
                    value={this.state.report?.recipientEmail}
                    show={
                      !this.state.report?.noResponse &&
                      !this.state.report?.account?.hideCorrespondence &&
                      this.state.report?.problemActivity?.productLine
                        ?.showCorrespondence
                    }
                  />
                  <ReportDetailField
                    text="Is a sample available for evaluation?"
                    value={this.state.report?.sampleAvailable}
                    show
                  />
                  <ReportDetailField
                    text="Is a sample return box needed?"
                    value={this.boolToString(
                      this.state.report?.returnBoxRequested
                    )}
                    show
                  />
                  <ReportDetailField
                    text="Ship To address"
                    value={this.state.report?.shipToAddress}
                    show={
                      !!this.state.report?.shipToAddress &&
                      this.state.report?.shipToAddress !== ""
                    }
                  />
                  <ReportDetailField
                    text="Sample Evaluation Results"
                    value={this.state.report?.sampleEvaluation}
                    show={
                      !!this.state.report?.sampleEvaluation &&
                      this.state.report?.sampleEvaluation !== ""
                    }
                  />

                  <ReportDetailField
                    text="Did you experience any alarms?"
                    value={this.state.report?.problemActivity?.productLine?.showAlarmsExperienced ? this.boolToString(this.state.report?.anyAlarms) : 'NA'}
                    show={
                      this.state.report?.problemActivity?.productLine
                        ?.showAlarmsExperienced
                    }
                    altText={
                      this.state.report?.problemActivity?.productLine
                        ?.alarmsExperiencedAltText
                    }
                  />

                  <ReportDetailField
                    text="alarm code(s)"
                    value={this.state.report?.alarmCodes}
                    show={this.state.report?.anyAlarms}
                  />
                </div>

                <h3>Problem Details</h3>

                <div className={`${styles.detailsTable}`}>
                  <ReportDetailField
                    text="When was the problem detected?"
                    value={this.state.report?.problemActivity?.description}
                    show
                  />
                  <ReportDetailField
                    text="When was the problem detected specifics"
                    value={this.state.report?.problemActivitySpecifics}
                    show={this.state.report?.problemActivity?.specify}
                  />
                  <ReportDetailField
                    text="Identify the problem type"
                    value={this.state.report?.problemDescription?.description}
                    show
                  />
                  <ReportDetailField
                    text="Identify the problem type specifics"
                    value={this.state.report?.problemDescriptionSpecifics}
                    show={this.state.report?.problemDescription?.specify}
                  />
                  <ReportDetailField
                    text="Did the hole/cut/tear/slice cause a leak?"
                    value={this.state.report?.product?.solution && this.state.report?.problemDescription?.hole ? this.boolToString(
                      this.state.report?.holeTearCutCauseLeak
                    ) : 'NA'}
                    show={
                      this.state.report?.product?.solution &&
                      this.state.report?.problemDescription?.hole
                    }
                  />
                  <ReportDetailField
                    text="Product leaking while adding pressure to the solution bag?"
                    value={this.state.report?.product?.solution &&
                      (this.state.report?.problemDescription?.leak ||
                        this.state.report?.problemDescription?.hole) ? this.boolToString(
                          this.state.report?.leakingWhileAddingPressure
                        ) : 'NA'}
                    show={
                      this.state.report?.product?.solution &&
                      (this.state.report?.problemDescription?.leak ||
                        this.state.report?.problemDescription?.hole)
                    }
                  />
                  <ReportDetailField
                    text="Where is the location of the leak?"
                    value={this.state.report?.leakLocation}
                    show={
                      this.state.report?.product?.solution &&
                      (this.state.report?.problemDescription?.leak ||
                        this.state.report?.problemDescription?.hole)
                    }
                  />
                  <ReportDetailField
                    text="Describe the type of leak"
                    value={this.state.report?.leakType}
                    show={
                      this.state.report?.product?.solution &&
                      this.state.report?.problemDescription?.leak
                    }
                  />
                  <ReportDetailField
                    text="Problem Location"
                    value={this.GetProblemLocation()}
                    show
                  />
                </div>

                <h3>Attached Files</h3>

                <div className={`${styles.detailsTable}`}>
                  {this.state.files.map((file, i) => {
                    return (
                      <div className={`${styles.detailsRow}`} key={`file-${i}`}>
                        <div className={`${styles.detailsCell}`}>
                          <a
                            href={`${this.state.apiUri}/uploads/fetch/${this.state.reportId}/${file}`}
                            target="_blank"
                          >
                            {file}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                  <ReportDetailField
                    text="Picture Evaluation Results"
                    value={this.state.report?.imageEvaluation}
                    show={
                      !!this.state.report?.imageEvaluation &&
                      this.state.report?.imageEvaluation !== ""
                    }
                  />
                </div>

                <h3
                  hidden={
                    !this.state.report ||
                    !this.state.report.additionalIncidentDescription ||
                    this.state.report.additionalIncidentDescription.length === 0
                  }
                >
                  Additional Incident Description
                </h3>
                <span>{this.state.report?.additionalIncidentDescription}</span>
              </div>

              <div className={`${styles.col} ${styles.sm12}`}>
                <ImageContainer
                  line={this.state.report?.problemActivity?.productLine}
                  onLocationSelected={() => {
                    return undefined;
                  }}
                  selectedLocation={this.state.report?.problemLocation}
                />
              </div>
            </div>
          </div>
        </div>
      </Authenticator>
    );
  }

  loadData() {
    this.loadReport();
    this.loadFiles();
    this.loadSurveyUrl();
  }

  loadReport() {
    ReportData.retrieveById(this.state.reportId).then((response) => {
      if (response.status === 200) {
        console.log(response.data);

        var report = response.data;

        console.log(report);

        if (report.problemActivity?.productLine) {
          report.problemActivity.productLine.problemLocations = [];

          if (report.problemLocation) {
            report.problemActivity.productLine.problemLocations = [
              report.problemLocation,
            ];
          }
        }

        this.setState({ report: report });
      }
    });
  }

  loadFiles() {
    FileData.list(this.state.reportId).then((response) =>
      this.setState({ files: response.data })
    );
  }

  loadSurveyUrl() {
    DataLayer.get<string>('/survey').then((response) =>
      this.setState({ surveyUrl: response.data })
    );
  }

  boolToString(val?: boolean) {
    if (val === true) return "Yes";
    if (val === false) return "No";
    return "";
  }
  GetProblemLocation(): string {
    switch (this.state.report?.locationType) {
      case LocationTypeIdEnum.Known:
        return this.state.report?.problemLocation?.description ?? "problem location missing";
      case LocationTypeIdEnum.Unknown:
        return LocationTypeDescriptionEnum.Unknown;
      case LocationTypeIdEnum.Packaging:
        return LocationTypeDescriptionEnum.Packaging;
      case LocationTypeIdEnum.Tubing:
        return LocationTypeDescriptionEnum.Tubing;
      default:
        return LocationTypeDescriptionEnum.Unknown
    }
  }
}
