import { IAccount } from "./Account";
import { INeedleDropdownOption } from "./NeedleDropdownOption";
import { IProblemActivity } from "./ProblemActivity";
import { IProblemDescription } from "./ProblemDescription";
import { IProblemLocation } from "./ProblemLocation";
import { IProduct } from "./Product";

export interface IReport {
    id?: number;
    notification?: string;

    status: string;
    creatorId: number;
    creator: string;
    creatorEmail: string;
    creatorPhone: string;
    creatorFax: string;
    imageEvaluation: string;
    sampleEvaluation: string;
    createdAt?: Date;
    hasAttachments: boolean;

    // Account Information 
    accountId: number;
    custRef: string;
    qaContact: string;

    // General Incident Information
    adverseEvent: boolean | undefined;
    adverseEventDescription: string;
    completed?: string;
    container: string;
    drugAdministered: string;
    drugUsed: string;
    duringDonation: boolean | undefined;
    isCytotoxic?: string;
    lost?: string;
    manufacturer: string;
    softGoodsIssue?: string;
    stopped?: string;
    clinicalOrPatientMaterial?: string;

    // Correspondence
    noResponse: boolean;
    recipientEmail: string;

    // Product Details
    productId: number;
    incidentDate?: Date;
    lotNumber: string;
    rbcLabelId: string;
    numIncidents: number;
    serialNumber: string;
    softwareVersion: string;
    productDescription: string;
    wbProcessed: string;
    volumeOfPlasmaCollected: string;
    donorBleedNumber: string;
    pasLotNumber: string;
    batchTagInfoTime: string;
    batchTagNumber: string;
    lineNumber: string;
    videoJetNumber: string;
    filterNumber: string;

    // Filter Issue Details
    filterNotRelated: boolean | undefined;
    filterTemperature: string;
    filterCentrifuge: string;
    filtrationTime: string;
    bloodAmount: string;
    filterFlatFilled: string;
    wbcValue: string;

    
    // Problem Details
    problemActivityId: number;
    problemActivitySpecifics: string;
    problemDescriptionId: number;
    problemDescriptionSpecifics: string;
    problemLocationId?: number;
    holeTearCutCauseLeak: boolean | undefined;
    leakingWhileAddingPressure: boolean | undefined;
    leakLocation: string;
    leakType: string;
    anyAlarms: boolean | undefined;
    alarmCodes: string;
    additionalIncidentDescription: string;
    locationType: number;
    
    // Return Details
    shipToAddress: string;
    sampleAvailable: string;
    returnBoxRequested: boolean | undefined;
    
    // attachments list
    attachments: string;
    
    // related objects
    
    account?: IAccount;
    product?: IProduct;
    problemActivity?: IProblemActivity;
    problemDescription?: IProblemDescription;
    problemLocation?: IProblemLocation;

    // Needle Issue Details
    needleIssueId?: number;
    needleNotRelated: boolean | undefined;
    needleDropdownOption?: INeedleDropdownOption;
}

export class Report implements IReport {
    id?: number;
    notification?: string;

    status: string = '';
    
    creatorId: number = 0;
    creator: string = '';
    creatorEmail: string = '';
    creatorPhone: string = '';
    creatorFax: string = '';
    imageEvaluation: string = '';
    sampleEvaluation: string = '';
    productLineId: number = 0;
    createdAt?: Date | undefined;
    hasAttachments: boolean = false;

    // Account Information 
    accountId: number = 0;
    custRef: string = '';
    qaContact: string = '';

    // General Incident Information
    adverseEvent: boolean = false;
    adverseEventDescription: string = '';
    container: string = '';
    drugAdministered: string = '';
    drugUsed: string = '';
    duringDonation: boolean = false;
    manufacturer: string = '';
    completed?: string;
    isCytotoxic?: string;
    lost?: string;
    softGoodsIssue?: string;
    stopped?: string;
    patientOrClinicalMaterial?: string;

    // Correspondence
    noResponse: boolean = false;
    recipientEmail: string = '';

    // Product Details
    incidentDate?: Date;
    productId: number = 0;
    lotNumber: string = '';
    rbcLabelId: string = '';
    numIncidents: number = 1;
    serialNumber: string = '';
    softwareVersion: string = '';
    productDescription: string = '';
    wbProcessed: string = '';
    volumeOfPlasmaCollected: string = '';
    donorBleedNumber: string = '';
    pasLotNumber: string = '';
    batchTagInfoTime: string = '';
    batchTagNumber: string = '';
    lineNumber: string = '';
    videoJetNumber: string = '';
    filterNumber: string = '';

    // Filter Issue Details
    filterNotRelated: boolean = false;
    filterTemperature: string = '';
    filterCentrifuge: string = '';
    filtrationTime: string = '';
    bloodAmount: string = '';
    filterFlatFilled: string = '';
    wbcValue: string = '';

    // Problem Details
    problemActivityId: number = 0;
    problemActivitySpecifics: string = '';
    problemDescriptionId: number = 0;
    problemDescriptionSpecifics: string= '';
    problemLocationId?: number;
    holeTearCutCauseLeak: boolean = false;
    leakingWhileAddingPressure: boolean = false;
    leakLocation: string = '';
    leakType: string = '';
    anyAlarms: boolean = false;
    alarmCodes: string = '';
    additionalIncidentDescription: string = '';
    locationType: number = 0;

    // Return Details
    shipToAddress: string = '';
    sampleAvailable: string = '';
    returnBoxRequested: boolean = false;

    // attachments list
    attachments: string = '';

    // related objects
    account?: IAccount;
    product?: IProduct;
    problemActivity?: IProblemActivity;
    problemDescription?: IProblemDescription;
    problemLocation?: IProblemLocation;

    // Needle Issue Details
    needleIssueId?: number;
    needleNotRelated = false;
    needleDropdownOption?: INeedleDropdownOption;
}
