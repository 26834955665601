import { IProductLine } from "../models/ProductLine";
import { IReport } from "../models/Report";
import { IValidateResult } from "./validateResult";

export class GeneralFormValidator {

    private report: IReport;
    private errors: string[];
    private line?: IProductLine;

    constructor(report: IReport, errors: string[], line?: IProductLine) {
        this.line = line;
        this.errors = errors;
        this.report = report;
    }

    validateAdverseEventDescription(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showAdverseEvent &&
            this.report.adverseEvent &&
            this.report.adverseEventDescription.length === 0) {

            this.errors.push('Please give a description of the event');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateDrugUsed(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showDrugUsed &&
            this.report.drugUsed.length === 0) {

            this.errors.push(this.line?.drugUsedAltText ? this.line?.drugUsedAltText : 'What drug was used for the infusion?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateDrugAdministered(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showDrugAdministered &&
            this.report.drugAdministered.length === 0) {

            this.errors.push(this.line?.drugAdministeredAltText ? this.line?.drugAdministeredAltText : 'If applicable, list name of any drug administered');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateManufacturer(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showManufacturer &&
            this.report.manufacturer.length === 0) {

            this.errors.push(this.line?.manufacturerAltText ? this.line?.manufacturerAltText : 'What company manufactured the container that was spiked?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateContainer(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showContainer &&
            this.report.container.length === 0) {

            this.errors.push(this.line?.containerAltText ? this.line?.containerAltText : 'What type of container was spiked (glass bottle, plastic bag, etc.)?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateCompleted(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showCompleted &&
            this.line?.completedRequired && (
                !this.report.completed ||
                this.report.completed.length === 0 )) {

            this.errors.push(this.line?.completedAltText ? this.line?.completedAltText : 'Was the procedure successfully completed?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateStopped(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showStopped &&
            this.line?.stoppedRequired && (
                !this.report.stopped ||
                this.report.stopped.length === 0 )) {

            this.errors.push(this.line?.stoppedAltText ? this.line?.stoppedAltText : 'Was the infusion stopped before completion?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateCytotoxic(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showIsCytotoxic &&
            this.line?.isCytotoxicRequired && (
                !this.report.isCytotoxic ||
                this.report.isCytotoxic.length === 0 )) {

            this.errors.push(this.line?.isCytotoxicAltText ? this.line?.isCytotoxicAltText : 'Is the drug used cytotoxic?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateSoftGoodsIssue(state: (err: boolean) => any): IValidateResult {
        let result = true;

        // Check if the 'Completed' question was answered 'No'
        const isCompletedNo = this.report.completed === 'No';

        // Validate 'Soft Goods Issue' only if 'Completed' is 'No'
        if (isCompletedNo) {
            // 'Soft Goods Issue' is required in this scenario
            if (!this.report.softGoodsIssue || this.report.softGoodsIssue.length === 0) {
                this.errors.push(
                    this.line?.softGoodsAltText || 'Was the procedure stopped due to a soft goods incident?'
                );
                result = false;
            }
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    //validateSoftGoodsIssue(state: (err: boolean) => any): IValidateResult {
    //    var result: boolean = true;

    //    if (this.line?.showSoftGoods &&
    //        this.line?.softGoodsRequired && (
    //            !this.report.softGoodsIssue ||
    //            this.report.softGoodsIssue.length === 0 )) {

    //        this.errors.push(this.line?.softGoodsAltText ? this.line?.softGoodsAltText : 'Was the procedure stopped due to a soft goods incident?');
    //        result = false;
    //    }

    //    return {
    //        state: state(!result),
    //        isValid: result,
    //    };
    //}

    validateLost(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showLost &&
            this.line?.lostRequired && (
                !this.report.lost ||
                this.report.lost.length === 0 )) {

            this.errors.push(this.line?.lostAltText ? this.line?.lostAltText : 'Was the product lost?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateClinicalOrPatientMaterial(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showClinicalOrPatientMaterial &&
            this.line?.clinicalOrPatientMaterialRequired && (
                !this.report.clinicalOrPatientMaterial ||
                this.report.clinicalOrPatientMaterial.length === 0 )) {

            this.errors.push(this.line?.clinicalOrPatientMaterialAltText ? this.line?.clinicalOrPatientMaterialAltText : 'Did the procedure involve clinical or patient material?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }
}
